import { IconHeartFilled, IconHeart } from "@tabler/icons-react";
import { toggleLikeRoom } from "../Api/tenantProfileAPI";
import { useNavigate } from "react-router-dom";
import { toggleApplyRoom } from "../Api/tenantProfileAPI";
import { useAuth } from "../Hooks/useAuth";

export function RoomCard(props: {
  room: any;
  idx: number;
  onToggle: () => void;
}) {
  const { userRole } = useAuth();
  console.log("hello", props.room.is_liked);
  const handleLikeClick = async (roomId: number, isLiked: boolean) => {
    // setToggle(!toggle);
    console.log(
      "hello2",
      `Icon clicked for room ID: ${roomId}, Liked status: ${isLiked}`
    );
    toggleLikeRoom(roomId, isLiked);
    props.onToggle();
  };

  console.log("hello3", props.room.is_applied);
  const handleApplyClick = async (roomId: number, isApplied: boolean) => {
    // setToggle(!toggle);
    console.log(
      "hello4",
      `Icon clicked for room ID: ${roomId}, Applied status: ${isApplied}`
    );
    
    toggleApplyRoom(roomId, isApplied);
    props.onToggle();
  };

  const navigate = useNavigate();

  return (
    <div key={props.idx} className="card bg-base-100 w-70 h-85 sm:w-96 md:w-1/2 lg:w-1/3 shadow-xl m-1 font-roboto">
      <figure>
        <img
          src={`${process.env.REACT_APP_API_ENDPOINT}/${props.room.image_file_name}`}
          alt="room_image"
          className="w-64 h-48 p-3"
          onClick={() => navigate(`/getRoomDetail/${props.room.id}`)}
        />
      </figure>

      <div className="card-body font-roboto ">
        <h2 className="card-title">
          {props.room.description}
          <div className="badge badge-secondary">NEW</div>
        </h2>
        <p>rent: $ {props.room.price}</p>
        {userRole === "tenant" ? (
          <div className="card-actions justify-end items-center">
            <div className="mr-5">
              {props.room.is_liked ? (
                <IconHeartFilled
                  className="text-red-500"
                  stroke={2}
                  onClick={() => {
                    console.log(
                      `Icon clicked for room ID: ${props.room.id}, Liked status: ${props.room.is_liked}`
                    );
                    handleLikeClick(props.room.id, props.room.is_liked);
                  }}
                />
              ) : (
                <IconHeart
                  stroke={2}
                  onClick={() => {
                    console.log(
                      `Icon clicked for room ID: ${props.room.id}, Liked status: ${props.room.is_liked}`
                    );

                    handleLikeClick(props.room.id, props.room.is_liked);
                  }}
                />
              )}
            </div>
            <button
              className="px-2 py-1 text-md bg-green-100 text-white-100 rounded-md hover:bg-green-600"
              onClick={() => {
                console.log(
                  `Button clicked for room ID: ${props.room.id}, Applied status: ${props.room.is_applied}`
                );
                handleApplyClick(props.room.id, props.room.is_applied);
              }}
            >
              Apply
            </button>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
