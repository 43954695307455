import { useState } from "react";
import { useAuth } from "../../Hooks/useAuth";
import { useNavigate } from "react-router-dom";

export default function LoginAdmin() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState("");
  const { login } = useAuth();

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    const isAdminLogin = email === "admin@gmail.com" && password === "admin";
    const userType = "admin";

    

    console.log("render Login Page ");
   

    setRole(userType);

    console.log("fetch login");
    const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/auth/login`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        emailInput: email,
        passwordInput: password,
        roleInput: userType,
      }),
    });

    const response = await res.json();

    if (res.ok) {
      login(response.token);
      console.log("Login successful");
    } else {
      alert("Login failed");
    }
  };

    return (
      <div className="relative flex flex-col justify-center h-screen overflow-hidden">
        <div className="w-full p-6 m-auto bg-brown-100 rounded-md shadow-md ring-2 ring-gray-800/50 lg:max-w-lg">
          <h1 className="text-3xl font-semibold text-center text-gray-700">
            TogetherHaven
          </h1>
          <form className="space-y-4" onSubmit={handleSubmit}>
            <div>
              <label className="label">
                <span className="text-base label-text">Email</span>
              </label>
              <input
                type="text"
                placeholder="Email Address"
                className="w-full input input-bordered"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div>
              <label className="label">
                <span className="text-base label-text">Password</span>
              </label>
              <input
                type="password"
                placeholder="Enter Password"
                className="w-full input input-bordered"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <br></br>

            <div>
              <button type="submit" className="btn-neutral btn btn-block">
                Login
              </button>
            </div>

            <div className="flex justify-normal">
              <a
                href="#"
                className="text-xs text-gray-600 hover:underline hover:text-blue-600 pr-3"
              >
                Forget Password?
              </a>
              <p></p>
              <a
                href="#"
                className="text-xs text-gray-600 hover:underline hover:text-blue-600"
                onClick={() => navigate("/register")}
              >
                Register Here
              </a>
            </div>
          </form>
        </div>
      </div>
    );
  };
