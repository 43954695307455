import { IconHeart, IconHeartFilled } from "@tabler/icons-react";
import React, { useEffect, useState } from "react";
import { toggleLikeRoom } from "../../Api/tenantProfileAPI";
import { RoomCard } from "../../Component/RoomCard";

export default function RoomLikedPage() {
  const [rooms, setRooms] = useState([]);
  const [toggle, setToggle] = useState(false);

  const handleToggle = () => {
    setToggle(!toggle);
  };
  useEffect(() => {
    const fetchRoom = async () => {
      try {
        const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/tenant/roomLiked`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("havenToken")}`,
          },
        });
        const response = await res.json();
        setRooms(response.data);
        // console.log(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchRoom();
  }, [toggle]);

  return (
    <>
      <div className="overflow-x-hidden">
        <div className="requestContainer max-w-full x-auto">
          <div className="adminRequestBox">
            <div className="indicator flex flex-col justify-center items-center w-full ">
              {rooms && rooms.length > 0 ? (
                rooms.map((room: any, idx) => (
                  <RoomCard room={room} idx={idx} onToggle={handleToggle} />
                ))
              ) : (
                <>No Rooms Found</>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
