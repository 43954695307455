import { IconArrowBackUp } from "@tabler/icons-react";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

export default function AdminTenantAppliedDetail() {
  const { id } = useParams();

  useEffect(() => {
    const fetchRoomDetails = async () => {
      try {
        const res = await fetch(
          `${process.env.REACT_APP_API_ENDPOINT}/admin/getRoomAppliedDetails/${id}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("havenToken")}`,
            },
          }
        );
        const response = await res.json();
        setRooms(response.data);
        console.log(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    if (id) fetchRoomDetails();
  }, [id]);

  const navigate = useNavigate();
  const [rooms, setRooms] = useState<{ id: number }[]>([]);

  return (
    <>
      <button
        type="button"
        className="btn btn-sm sm:btn-md md:btn-md lg:btn-lg"
        onClick={() => navigate(-1)}
      >
        <IconArrowBackUp stroke={2} />{" "}
      </button>

      <div className="overflow-x-hidden ">
        <div className="requestContainer max-w-full mx-auto ">
          <div className="indicator flex flex-col gap-3 justify-center items-center w-full ">
            {rooms.length > 0 ? (
              rooms.map((room: any, idx) => (
                <div
                  key={idx}
                  className="card bg-base-100 w-70 shadow-2xl my-3"
                >
                  <img
                    src={`${process.env.REACT_APP_API_ENDPOINT}/${room.image_file_name}`}
                    alt="room_image"
                    className="w-80 h-80 pt-2"
                  />

                  <div className="card-body">
                    <div className="flex justify-between items-center gap-4">
                      <h2 className="card-title text-sm">{room.description}</h2>

                      <p className="badge badge-secondary text-sm px-2 py-0.5">
                        {room.status}
                      </p>
                    </div>
                    <p>rent: $ {room.price}</p>
                    <p>District: {room.district}</p>
                    <p>
                      {room.tenant_id.length > 0 ? (
                        <div>
                          <p>Applicant:</p>
                          {room.tenant_id.map((tenant: any) => (
                            <p>
                              {tenant.first_name} {tenant.last_name}
                            </p>
                          ))}
                        </div>
                      ) : (
                        <div>No applicants found</div>
                      )}
                    </p>
                  </div>
                </div>
              ))
            ) : (
              <div>No rooms found</div>
            )}
            <div className="buttonContainer justify-center items-center space-x-4">
              <button 
                type="submit"
                onClick={() => {
                  console.log("Approve");
                  //   updateStatus("available");
                  const modal = document.getElementById(
                    "my_modal_1"
                  ) as HTMLDialogElement;
                  if (modal) {
                    modal.showModal();
                  }
                }}
                className="btn btn-secondary"
              >
                Approve
              </button>

                <dialog id="my_modal_1" className="modal">
                  <div className="modal-box">
                    <h3 className="font-bold text-lg">
                      Room Application Approved!
                    </h3>
                    <p className="py-4 justify-center items-center font-normal">
                      Click the button below to close and return back to the
                      previous page
                    </p>
                    <div className="modal-action">
                      <form method="dialog">
                        {/* if there is a button in form, it will close the modal */}
                        <button className="btn" onClick={() => navigate(-1)}>
                          Close
                        </button>
                      </form>
                    </div>
                  </div>
                </dialog>
\
            \

              <button
                type="submit"
                onClick={() => {
                  //   updateStatus("rejected");
                  const modal = document.getElementById(
                    "my_modal_2"
                  ) as HTMLDialogElement;
                  if (modal) {
                    modal.showModal();
                  }
                }}
                className="btn btn-primary"
              >
                <dialog id="my_modal_2" className="modal">
                  <div className="modal-box">
                    <h3 className="font-bold text-lg">
                      Room Application Rejected
                    </h3>

                    <label className="form-control">
                      <div className="label">
                        <span className="label-text font-normal pt-4">
                          Please explain why the application was rejected
                        </span>
                      </div>
                      <textarea
                        className="textarea textarea-bordered h-24"
                        placeholder="Input your comment here"
                      ></textarea>
                    </label>

                    <p className="py-4 justify-start items-start font-normal mt-4">
                      Click the button below to close and submit comment.
                      Returning back to the previous page.
                    </p>
                    <div className="modal-action">
                      <form method="dialog">
                        {/* if there is a button in form, it will close the modal */}
                        <button className="btn" onClick={() => navigate(-1)}>
                          Close
                        </button>
                      </form>
                    </div>
                  </div>
                </dialog>
                Reject
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
