import { useEffect, useState } from "react";
import { RoomCard } from "./RoomCard";
// import { useNavigate } from "react-router-dom";

export default function TenantSearchResults(props: {
  regionInput: string;
  maxPriceInput: number;
  toggle: boolean;
}) {
  const [rooms, setRooms] = useState([]);
  const [toggle, setToggle] = useState(false);

  const handleToggle = () => {
    setToggle(!toggle);
  };

  console.log("check props", props.regionInput, props.maxPriceInput);
  const fetchSearchResults = async () => {
    try {
      const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/tenant/searchRooms`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("havenToken")}`,
        },
        body: JSON.stringify({
          regionInput: props.regionInput,
          maxPriceInput: props.maxPriceInput,
        }),
      });
      const response = await res.json();
      setRooms(response.data);
      console.log(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchSearchResults();
  }, [toggle, props.toggle]);

  // const navigate = useNavigate();

  return (
    <div>
      <div className="overflow-x-hidden ">
        <div className="requestContainer max-w-full mx-auto ">
          <div className="indicator flex flex-col gap-3 justify-center items-center w-full ">
            {rooms.length > 0 ? (
              rooms.map((room: any, idx) => (
                <RoomCard
                  key={idx}
                  room={room}
                  idx={idx}
                  onToggle={handleToggle}
                />
              ))
            ) : (
              <div>No rooms found</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
