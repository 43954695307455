import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export default function AdminRequests() {
  const [rooms, setRooms] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchRoom = async () => {
      try {
        const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/admin/pendingRooms`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("havenToken")}`,
          },
        });
        const response = await res.json();
        setRooms(response.data);
        console.log(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchRoom();
  }, []);

  return (
    <div>
      <div className="requestContainer">
        <div className="adminRequestBox">
          <div className="indicator flex flex-col gap-4 justify-center items-center w-full">
            <div className="card bg-base-100 w-60 shadow-xl">
              {rooms.length > 0 ? (
                rooms.map((room: any) => (
                  <div
                    className="card bg-base-100 w-60 shadow-2xl my-2"
                    onClick={() => navigate(`/PendingRoomDetail/${room.id}`)}
                  >
                    <figure>
                      <img
                        src={`${process.env.REACT_APP_API_ENDPOINT}/${room.image_file_name}`}
                        alt="room_image"
                        className="w-40 h-40 pt-3"
                      />
                    </figure>

                    <div className="card-body">
                      <h5 className="card-title">
                        {room.description}!
                        <div className="flex flex-col">
                        <div className="badge badge-secondary">NEW</div>
                        <div className="badge badge-secondary">{room.status}</div>
                        </div>
                      </h5>
                      <p>rent: $ {room.price}</p>
                    </div>
                  </div>
                ))
              ) : (
                <div>No rooms found</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
