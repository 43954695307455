import React, { useState } from "react";
import { useLandlordProfile } from "../Api/landlordProfileAPI";
import {
  IconMail,
  IconLock,
  IconPhone,
  IconLogout,
  IconChevronRight,
  IconInfoSquareRounded,
  IconUserCircle,
} from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../Hooks/useAuth";
import ChangePasswordModal from "../Component/ChangePasswordModal";

export default function LandlordProfileComponent() {
  const landlordProfile = useLandlordProfile();
  const navigate = useNavigate();
  const { logout } = useAuth();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => {
    console.log("open modal");
    setIsModalOpen(true);
  };
  const closeModal = () => setIsModalOpen(false);

  const handleLogout = async () => {
    await logout();
    navigate("/login");
  };



  return (
    <div className="container">
      <div className="board">
        <div className="fullName">
          {/* {" "} */}
          <div id="iconUser">
            <IconUserCircle stroke={1.5} />{" "}
          </div>{" "}
          <p>
            {landlordProfile.status === "success" ? (
              landlordProfile.data?.first_name +
              " " +
              landlordProfile.data?.last_name +
              " | " +
              landlordProfile.data?.last_name_in_chinese +
              landlordProfile.data?.first_name_in_chinese
            ) : (
              <></>
            )}
          </p>
        </div>
       

       
        <hr className="divider1" />
        <div className="email">
          <div id="iconEmail">
            <IconMail stroke={1.5} />{" "}
          </div>
          <p>
            {landlordProfile.status === "success" ? (
              landlordProfile.data?.email
            ) : (
              <></>
            )}
          </p>
        </div>
        <hr className="divider1" />
        <div className="passWord" onClick={openModal}>
          <div className="passWord2">
            <div id="iconLock">
              <IconLock stroke={1.5} />{" "}
            </div>
            <p>Change your password</p>
          </div>
          <div id="iconChevronRight">
            <IconChevronRight stroke={1.5} />{" "}
          </div>
        </div>
        <hr className="divider1" />
        <div className="phone">
          <div className="phone2">
            <div id="iconPhone">
              <IconPhone stroke={1.5} />{" "}
            </div>
            <p>
              {landlordProfile.status === "success" ? (
                landlordProfile.data?.phone_number
              ) : (
                <></>
              )}
            </p>
          </div>
          <div id="iconChevronRight">
            <IconChevronRight stroke={1.5} />{" "}
          </div>
        </div>
        <hr className="divider1" />
        <div className="logout">
          <div id="iconLogout">
            <IconLogout stroke={1.5} />{" "}
          </div>
          <p onClick={handleLogout}>Logout</p>
        </div>
      </div>
      {isModalOpen ? <ChangePasswordModal onClose={closeModal} /> : <> </>}
    </div>
  );
}
