import AdminRequests from "../../Component/AdminRequests";
import AdminTenantRequests from "../../Component/AdminTenantRequests";
import TenantFilterSearch from "../../Component/TenantFilterSearch";

export default function AdminPage() {
  return (
    <div>
      <div role="tablist" className="tabs tabs-lifted pt-5 px-3">
        <input
          type="radio"
          name="my_tabs_2"
          role="tab"
          className="tab"
          aria-label="Pending Room "
        />
        <div
          role="tabpanel"
          className="tab-content bg-base-100 border-base-300 rounded-box p-6 "
        >
          <AdminRequests />
        </div>

        <input
          type="radio"
          name="my_tabs_2"
          role="tab"
          className="tab"
          aria-label="Rooms"
          defaultChecked
        />
        <div
          role="tabpanel"
          className="tab-content bg-base-100 border-base-300 rounded-box p-6"
        >
          {/* <AdminTenantRequests /> */}
          <TenantFilterSearch />
        </div>

        <input
          type="radio"
          name="my_tabs_2"
          role="tab"
          className="tab"
          aria-label="Schedule MeetUp"
        />
        <div
          role="tabpanel"
          className="tab-content bg-base-100 border-base-300 rounded-box p-6"
        >
          Schedule MeetUp
        </div>
      </div>
    </div>
  );
}
