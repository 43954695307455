// ChangePasswordModal.tsx
import React, { useState } from "react";

type ChangePasswordModalProps = {
  onClose: () => void;
};

function ChangePasswordModal({ onClose }: ChangePasswordModalProps) {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [enterAgain, setEnterAgain] = useState("");

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    console.log(oldPassword);
    console.log(newPassword);
    console.log(enterAgain);
    let res = await fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/tenant/profilePassword`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("havenToken")}`,
        },
        body: JSON.stringify({
          old_password: oldPassword,
          new_password: newPassword,
          enter_again: enterAgain,
        }),
      }
    );

    if (res.ok) {
      alert("Password Changed Successfully");
      onClose();
    } else {
      alert("Password Change Failed");
    }
  };

  return (
    <div className="modal-backdrop">
      <div className="passwordModal">
        <form onSubmit={handleSubmit}>
          <div className="modal-content">
            <label htmlFor="oldPassword">Old Password</label>
            <input
              type="password"
              id="oldPassword"
              value={oldPassword}
              onChange={(e) => setOldPassword(e.target.value)}
              required
            />
          </div>
          <div className="modal-content">
            <label htmlFor="newPassword">New Password</label>
            <input
              type="password"
              id="newPassword"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
            />
          </div>
          <div className="modal-content">
            <label htmlFor="confirmPassword">Enter Again</label>
            <input
              type="password"
              id="confirmPassword"
              value={enterAgain}
              onChange={(e) => setEnterAgain(e.target.value)}
              required
            />
          </div>
          <div className="modal-actions">
            <button className="close" onClick={onClose}>
              Cancel
            </button>
            <button type="submit" className="submit">
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ChangePasswordModal;
