import LandlordProperties from "../../Component/LandlordProperties";
// import UploadDataFormat from "../../Component/UploadDataFormat";
import { useState } from "react";
import { useAuth } from "../../Hooks/useAuth";
import { error } from "console";
import LandlordPendingApprove from "../../Component/LandlordPendingApprove";
// Assuming the correct component name is 'UploadDataFormat'

export default function LandlordPage() {
  const { userRole } = useAuth();

  const [userType, setUserType] = useState("landlord");
  const initialFormData = {
    file: null,
    descriptionInput: "",
    spaceInput: "",
    studioInput: "",
    priceInput: "",
    bedroomInput: "",
    bathroomInput: "",
    kitchenInput: "",
    livingRoomInput: "",
    sharedLivingRoomInput: "",
    sharedBathroomInput: "",
    sharedKitchenInput: "",
  };

  const initialDistrict = "";

  const [formData, setFormData] = useState<any>(initialFormData);

  const [selectedDistrict, setSelectedDistrict] = useState(initialDistrict);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const closeModal = () => {
    setIsModalOpen(false);
  };
  const openModal = () => {
    setIsModalOpen(true);
  };

  const handleDistrictChange = (e: any) => {
    setSelectedDistrict(e.target.value);
  };

  const handleChange = (e: any) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    let formData = new FormData(e.target);

    if (!selectedDistrict) {
      alert("Please select a district");
      return;
    }

    // console.log("form submitted", formData);
    // console.log(...formData);
    const endpoint =
      userRole === "landlord"
        ? "create"
        : "you do not have access to this page";

    const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/landlord/${endpoint}`, {
      method: "POST",
      headers: {
        Authorization: "Bearer " + String(localStorage.getItem("todoToken")),
      },
      body: formData,
    });

    const response = await res.json();
    if (res.ok) {
      // console.log("upload success");
    } else {
      alert("upload failed");
    }
    setFormData(initialFormData);
    setSelectedDistrict(initialDistrict);
    closeModal();
  };

  return (
    <>
      <div>
        <div
          role="tablist"
          className="tabs tabs-lifted pt-5 px-3 overflow-hidden"
        >
          <input
            type="radio"
            name="my_tabs_2"
            role="tab"
            className="tab"
            aria-label="Existing"
          />
          <div
            role="tabpanel"
            className="tab-content bg-base-100 border-base-300 rounded-box p-6 "
          >
            <LandlordProperties />
          </div>
          <input
            type="radio"
            name="my_tabs_2"
            role="tab"
            className="tab"
            aria-label="New"
            defaultChecked
          />
          <div
            role="tabpanel"
            className="tab-content bg-base-100 border-base-300 rounded-box p-6 display:flex justify-center"
          >
            Click Here to Begin Adding Properties <p></p>
            <br></br>
            <button className="btn" onClick={openModal}>
              Click Here!
            </button>
            {isModalOpen && (
              <dialog id="uploadProperty" className="modal" open>
                <form encType="multipart/form-data" onSubmit={handleSubmit}>
                  <div className="modal-box">
                    {/*-------------------------------------------------------------------------------------*/}
                    <div>
                      {/* District Area */}
                      <select
                        className="select select-bordered w-full max-w-xs my-4"
                        name="districtInput"
                        value={selectedDistrict}
                        onChange={handleDistrictChange}
                      >
                        <option disabled value="">
                          Choose District Area
                        </option>
                        <option value="New Territories">New Territories</option>
                        <option value="Kowloon">Kowloon</option>
                        <option value="Hong Kong Island">
                          Hong Kong Island
                        </option>
                      </select>

                      {/* Room Price */}
                      <label className="form-control w-full max-w-xs mb-4">
                        <div className="label">
                          <span className="label-text">Room Price</span>
                        </div>
                        <input
                          type="text"
                          placeholder="Room Price"
                          name="priceInput"
                          value={formData.priceInput}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              priceInput: e.target.value,
                            })
                          }
                          className="input input-bordered w-full max-w-xs"
                        />
                      </label>

                      {/* Room Space */}
                      <label className="form-control w-full max-w-xs mb-4">
                        <div className="label">
                          <span className="label-text">Room Space</span>
                        </div>
                        <input
                          type="text"
                          placeholder="Room Space"
                          value={formData.spaceInput}
                          name="spaceInput"
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              spaceInput: e.target.value,
                            })
                          }
                          className="input input-bordered w-full max-w-xs"
                        />
                      </label>

                      {/* RoomType Studio */}
                      <label className="form-control w-full max-w-xs mb-4">
                        <div className="labe">
                          <span className="label-text">Studio</span>
                        </div>
                        <input
                          type="checkbox"
                          value={formData.studioInput.toString()} // Convert boolean to string
                          name="studioInput"
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              studioInput: e.target.checked!.toString(), // Convert boolean to string
                            })
                          }
                          className="checkbox display:flex justify-center"
                        />
                      </label>

                      {/* Bedroom Number */}
                      <label className="form-control w-full max-w-xs">
                        <div className="label">
                          <span className="label-text">Bedroom Number</span>
                        </div>
                        <input
                          type="number"
                          name="bedroomInput"
                          placeholder="Bedroom Number"
                          value={formData.bedroomInput}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              bedroomInput: e.target.value,
                            })
                          }
                          className="input input-bordered w-full max-w-xs"
                        />
                      </label>

                      {/* Bathroom Number */}
                      <label className="form-control w-full max-w-xs">
                        <div className="label">
                          <span className="label-text">Bathroom Number</span>
                        </div>
                        <input
                          type="number"
                          name="bathroomInput"
                          placeholder="Bathroom Number"
                          value={formData.bathroomInput}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              bathroomInput: e.target.value,
                            })
                          }
                          className="input input-bordered w-full max-w-xs"
                        />
                      </label>

                      {/* Kitchen Number */}
                      <label className="form-control w-full max-w-xs">
                        <div className="label">
                          <span className="label-text">Kitchen Number</span>
                        </div>
                        <input
                          type="number"
                          name="kitchenInput"
                          placeholder="Kitchen Number"
                          value={formData.kitchenInput}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              kitchenInput: e.target.value,
                            })
                          }
                          className="input input-bordered w-full max-w-xs"
                        />
                      </label>

                      {/* LivingRoom Number */}
                      <label className="form-control w-full max-w-xs">
                        <div className="label">
                          <span className="label-text">Living Room Number</span>
                        </div>
                        <input
                          type="number"
                          name="livingRoomInput"
                          placeholder="Living Room Number"
                          value={formData.livingRoomInput}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              livingRoomInput: e.target.value,
                            })
                          }
                          className="input input-bordered w-full max-w-xs"
                        />
                      </label>

                      {/* Room Description */}

                      <label className="form-control w-full max-w-xs mb-4">
                        <div className="label">
                          <span className="label-text">Room Description</span>
                        </div>
                        <input
                          type="text"
                          placeholder="Room Description"
                          value={formData.descriptionInput}
                          name="descriptionInput"
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              descriptionInput: e.target.value,
                            })
                          }
                          className="input input-bordered w-full max-w-xs"
                        />
                      </label>

                      {/* Shared_living_room */}
                      <label className="form-control w-full max-w-xs mb-4">
                        <div className="labe">
                          <span className="label-text">Shared Living Room</span>
                        </div>
                        <input
                          type="checkbox"
                          value={formData.sharedLivingRoomInput.toString()} // Convert boolean to string
                          name="sharedLivingRoomInput"
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              sharedLivingRoomInput:
                                e.target.checked!.toString(), // Convert boolean to string
                            })
                          }
                          className="checkbox display:flex justify-center"
                        />
                      </label>

                      {/* shared_bathroom */}
                      <label className="form-control w-full max-w-xs mb-4">
                        <div className="labe">
                          <span className="label-text">Shared Bathroom</span>
                        </div>
                        <input
                          type="checkbox"
                          value={formData.sharedBathroomInput.toString()} // Convert boolean to string
                          name="sharedBathroomInput"
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              sharedBathroomInput: e.target.checked!.toString(), // Convert boolean to string
                            })
                          }
                          className="checkbox display:flex justify-center"
                        />
                      </label>

                      {/* Shared_Kitchen */}
                      <label className="form-control w-full max-w-xs mb-4">
                        <div className="labe">
                          <span className="label-text">Shared Kitchen</span>
                        </div>
                        <input
                          type="checkbox"
                          value={formData.sharedKitchenInput.toString()} // Convert boolean to string
                          name="sharedKitchenInput"
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              sharedKitchenInput: e.target.checked!.toString(), // Convert boolean to string
                            })
                          }
                          className="checkbox display:flex justify-center"
                        />
                      </label>

                      {/* upload file */}
                      <input
                        name="imageUpload"
                        type="file"
                        className="file-input file-input-bordered w-full max-w-xs mb-4"
                        placeholder="Upload Image"
                      />
                    </div>

                    {/* Corrected the component name */}
                    <div className="modal-action display:flex justify-center ">
                      <button
                        className="btn mr-4"
                        type="submit"
                        onClick={() => {
                          (
                            document.getElementById(
                              "my_modal_5"
                            ) as HTMLDialogElement
                          )?.showModal();
                        }}
                      >
                        Submit
                      </button>
                      <dialog
                        id="my_modal_5"
                        className="modal modal-bottom sm:modal-middle"
                      >
                        <div className="modal-box">
                          <h3 className="font-bold text-lg">
                            Registration successful!
                          </h3>
                          <p className="py-4">
                            Click the button below to close
                          </p>
                          <div className="modal-action">
                            <form method="dialog">
                              {/* if there is a button in form, it will close the modal */}
                              <button onClick={closeModal} className="btn">
                                Close
                              </button>
                            </form>
                          </div>
                        </div>
                      </dialog>

                      {/* if there is a button in form, it will close the modal */}
                      <button
                        type="button"
                        onClick={closeModal}
                        className="btn ml-3"
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </form>
              </dialog>
            )}
          </div>
          {/*----------------------------------------------------------------------------------------------*/}
          <input
            type="radio"
            name="my_tabs_2"
            role="tab"
            className="tab"
            aria-label="Pending Approved"
          />
          <div
            role="tabpanel"
            className="tab-content bg-base-100 border-base-300 rounded-box p-6"
          >
          <LandlordPendingApprove/>
          </div>
        </div>
      </div>
    </>
  );
}
