import React, { useState } from "react";
import "../../Css/InfoListPage.css";
import {
  updateTenantMoreInfo,
  useTenantProfile,
} from "../../Api/tenantProfileAPI";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";

function InfoListPage() {
  // const [formData, setFormData] = useState({ name: "", email: "" });

  const queryClient = useQueryClient();
  const tenantProfile = useTenantProfile();

  // console.log(tenantProfile);

  // const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
  //   const { name, value } = e.target;
  //   setFormData({ ...formData, [name]: value });
  // };
  const onUpdateMoreInfo = useMutation({
    mutationFn: async (data: {
      isMarried: boolean;
      number_of_residents: number;
    }) => updateTenantMoreInfo(data),
    onSuccess: () => {
      alert("save successfully")// console.log("update success");
      queryClient.invalidateQueries({ queryKey: ["tenantProfile"] });
    },
  });

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // console.log("handling submit", e.target);
    // 处理提交逻辑
    let formData = new FormData(e.target as HTMLFormElement);
    // console.log(...formData);
    var object: any = {};
    formData.forEach((value, key) => (object[key] = value));
    // console.log(object);

    onUpdateMoreInfo.mutate(object);
  };

  return tenantProfile.status === "success" ? (
    <>
      <form onSubmit={handleSubmit}>
        <div className="title">
          <p>Edit Profile</p>
        </div>
        <div className="profile">
          <div className="genderBox">
            <label className="gender">Gender</label>
            <div className="vertical-divider"></div>
            <input
              type="text"
              name="gender"
              placeholder={tenantProfile.data?.gender ? "Male" : "Female"}
              className="input input-bordered w-full max-w-xs"
              disabled
            />
          </div>
          <div className="marriedBox">
            <label className="married">Is Married</label>
            <div className="vertical-divider"></div>
            <select
              className="select select-secondary w-full max-w-xs"
              title="isMarried"
              name="isMarried"
              defaultValue={tenantProfile.data?.isMarried ? "Yes" : "No"}
            >
              {/* <option
      disabled
      selected
      value={tenantProfile.data?.isMarried ? "Yes" : "No"}
    >
      {tenantProfile.data?.isMarried ? "Yes" : "No"}
    </option> */}
              <option value={"Yes"}>Yes</option>
              <option value={"No"}>No</option>
            </select>
          </div>
          <div className="residentsBox">
            <label className="residents">Number of Residents</label>
            <div className="vertical-divider"></div>
            <input
              type="number"
              defaultValue={tenantProfile.data?.number_of_residents}
              className="input input-bordered input-secondary w-full max-w-xs"
              name="number_of_residents"
            />
          </div>
          <div className="employmentBox">
            <label className="employmentStatus">Employment Status</label>
            <div className="vertical-divider"></div>
            <select
              className="select select-secondary w-full max-w-xs"
              title="employmentStatus"
              name="employment_status"
              defaultValue={
                tenantProfile.data?.employment_status
                  ? tenantProfile.data?.employment_status
                  : "Not applicable"
              }
            >
              <option value={"Worker"}>Worker</option>
              <option value={"Student"}>Student</option>
              <option value={"Between jobs"}>Between jobs</option>
              <option value={"Retired"}>Retired</option>
              <option value={"Not applicable"}>Not applicable</option>
            </select>
          </div>
          <div className="kidsBox">
            <label className="kids">Have Kids</label>
            <div className="vertical-divider"></div>
            <select
              className="select select-secondary w-full max-w-xs"
              title="hasKids"
              name="has_kids"
              defaultValue={tenantProfile.data?.has_kids ? "Yes" : "No"}
            >
              <option value={"Yes"}>Yes</option>
              <option value={"No"}>No</option>
            </select>
          </div>
          <div className="educationBox">
            <label className="education">Youngest Kid's Education Level</label>
            <div className="vertical-divider"></div>
            <select
              className="select select-secondary w-full max-w-xs"
              title="education"
              name="youngest_kid_education_level"
              defaultValue={
                tenantProfile.data?.youngest_kid_education_level
                  ? tenantProfile.data?.youngest_kid_education_level
                  : "Not applicable"
              }
            >
              <option value={"Primary"}>Primary</option>
              <option value={"Secondary"}>Secondary</option>
              <option value={"Tertiary"}>Tertiary</option>
              <option value={"Not applicable"}>Not applicable</option>
            </select>
          </div>
        </div>
        <div className="saveButton">
          {" "}
          <button type="submit" className="btn btn-outline btn-secondary">
            Save changes
          </button>{" "}
        </div>
      </form>
    </>
  ) : (
    <div>Loading...</div>
  );
}
export default InfoListPage;
