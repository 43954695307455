import {useQuery} from "@tanstack/react-query";
import { useParams } from "react-router-dom";



interface AdminProfile {
name: string;   
email: string;

}

export function useAdminProfile() {



  const { isLoading, error, data, isFetching } = useQuery<AdminProfile, Error>(
    {
      queryKey: ["adminProfile"],
      queryFn: async () => {
        const response = await fetch(
          `${process.env.REACT_APP_API_ENDPOINT}/admin/profile`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("havenToken")}`,
            },
          }
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      },
    }
  );

  if (isLoading || isFetching) {
    return { status: "loading" };
  } else if (error) {
    return { status: "error", message: error.message };
  } else if (!data) {
    return { status: "no data" };
  }

  return { status: "success", data: data };
}
