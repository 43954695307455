import React, { useState } from "react";
import { useAdminProfile } from "../Api/adminProfileAPI";
import {
  IconMail,
  IconLock,
  IconLogout,
  IconChevronRight,
  IconUserCircle,
} from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../Hooks/useAuth";
import ChangePasswordModal from "../Component/ChangePasswordModal";

export default function AdminProfileComponent() {
  const adminProfile = useAdminProfile();
  const navigate = useNavigate();
  const { logout } = useAuth();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => {
    setIsModalOpen(true);
  };
  const closeModal = () => setIsModalOpen(false);

  const handleLogout = async () => {
    await logout();
    navigate("/login");
  };

  const handleClick = () => {
    navigate("/info-list");
  };

  return (
    <div>
      <div className="container">
        <div className="board">
          <div className="fullName">
            {/* {" "} */}
            <div id="iconUser">
              <IconUserCircle stroke={1.5} />{" "}
            </div>{" "}
            <p>
              {adminProfile.status === "success" ? (
                adminProfile.data?.name 
 
              ) : (
                <></>
              )}
            </p>
          </div>
          <hr className="divider1" />

          
          <div className="email">
            <div id="iconEmail">
              <IconMail stroke={1.5} />{" "}
            </div>
            <p>
              {adminProfile.status === "success" ? (
                adminProfile.data?.email
              ) : (
                <></>
              )}
            </p>
          </div>
          <hr className="divider1" />
          <div className="passWord" onClick={openModal}>
            <div className="passWord2">
              <div id="iconLock">
                <IconLock stroke={1.5} />{" "}
              </div>
              <p>Change your password</p>
            </div>
            <div id="iconChevronRight">
              <IconChevronRight stroke={1.5} />{" "}
            </div>
          </div>
          
            

          <hr className="divider1" />
          <div className="logout">
            <div id="iconLogout">
              <IconLogout stroke={1.5} />{" "}
            </div>
            <p onClick={handleLogout}>Logout</p>
          </div>
        </div>
        {isModalOpen ? <ChangePasswordModal onClose={closeModal} /> : <> </>}
      </div>
    </div>
  );
}
