import { useState } from "react";
import { useAuth } from "../../Hooks/useAuth";
import { useNavigate } from "react-router-dom";

export default function LoginPage() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState("");
  const [checkedState, setCheckedState] = useState({
    tenant: false,
    landlord: false,
  });
  const [isPressed, setIsPressed] = useState(false);

  const [clickCount, setClickCount] = useState(0);
  const [isAdmin, setIsAdmin] = useState(false);

  const handTitleClick = () => {
    const newCount = clickCount + 1;
    setClickCount(newCount);
    if (clickCount === 4) {
      setIsAdmin(true);
      navigate("/loginadmin");
      setClickCount(0);
    }
    setIsPressed(true);
    setTimeout(() => {
      setIsPressed(false);
    }, 150);
  };

  // console.log("render Login Page ");
  const { login } = useAuth();

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    // const isAdminLogin = email === "admin@gmail.com" && password === "admin";
    // const userType = (checkedState.tenant ? "tenant" : "landlord")

    let currentRole = "";

    try {
      if (checkedState.tenant) {
        console.log("tenant checked");
        currentRole = "tenant";
      } else if (checkedState.landlord) {
        console.log("landlord checked");
        currentRole = "landlord";
      } else {
        alert("Please select a role");
        return;
      }
    } catch (e) {
      throw new Error("Error in role selection");
    }

    setRole(currentRole);

    // console.log("fetch login");
    const res = await fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/auth/login`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          emailInput: email,
          passwordInput: password,
          roleInput: currentRole,
        }),
      }
    );

    const response = await res.json();

    if (res.ok) {
      login(response.token);
      // console.log("Login successful");
    } else {
      alert("Login failed");
    }
  };

  const handleCheckboxChange = (event: {
    target: { name: any; checked: boolean };
  }) => {
    const { name, checked } = event.target;

    // Update state based on which checkbox was clicked
    setCheckedState({
      tenant: false,
      landlord: false,
      [name]: checked,
    });
  };

  return (
    <>
      {/* <div className="w-32 h-32">
    <img
       src={process.env.REACT_APP_API_ENDPOINT + "/logo.jpg"}
       alt="logo" />
   </div> */}
      <div className="relative flex flex-col justify-center h-screen overflow-hidden">
        <div className="w-full p-6 m-auto bg-brown-100 rounded-md shadow-md ring-2 ring-gray-800/50 lg:max-w-lg">
          <h1
            className={`text-3xl font-semibold text-center text-gray-700 cursor-pointer hover:text-gray-900 transition duration-2 ease-in-out ${
              isPressed ? "translate-y-1" : ""
            }`}
            onClick={handTitleClick}
            onMouseDown={() => setIsPressed(true)}
            onMouseUp={() => setIsPressed(false)}
            onMouseLeave={() => setIsPressed(false)}
          >
            TogetherHaven
          </h1>
          <form className="space-y-4" onSubmit={handleSubmit}>
            <div>
              <label className="label">
                <span className="text-base label-text">Email</span>
              </label>
              <input
                type="text"
                placeholder="Email Address"
                className="w-full input input-bordered"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div>
              <label className="label">
                <span className="text-base label-text">Password</span>
              </label>
              <input
                type="password"
                placeholder="Enter Password"
                className="w-full input input-bordered"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </div>
            <br></br>

            <div className="flex justify-evenly">
              <input
                type="checkbox"
                aria-label="Tenant"
                className="btn pl-4"
                name="tenant"
                checked={checkedState.tenant}
                onChange={handleCheckboxChange}
              />
              <input
                type="checkbox"
                aria-label="Landlord"
                className="btn pr-4"
                name="landlord"
                checked={checkedState.landlord}
                onChange={handleCheckboxChange}
              />
            </div>

            <div>
              <button type="submit" className="btn-neutral btn btn-block">
                Login
              </button>
            </div>

            <div className="flex justify-normal">
              <a
                href="#"
                className="text-xs text-gray-600 hover:underline hover:text-blue-600 pr-3"
              >
                Forget Password?
              </a>
              <p></p>
              <a
                href="#"
                className="text-xs text-gray-600 hover:underline hover:text-blue-600"
                onClick={() => navigate("/register")}
              >
                Register Here
              </a>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}
