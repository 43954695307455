import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

export default function RegisterPage() {
  const [userType, setUserType] = useState("");
  const [showPopup, setShowPopup] = useState(false);

  const navigate = useNavigate();

  const handleSignUp = (e: { preventDefault: () => void }) => {
    e.preventDefault();

    setShowPopup(true);
    // console.log("popup open");
  };

  const closePopup = () => {
    // console.log("close popup");
    setShowPopup(false);
    navigate("/login");
  };

  const [formData, setFormData] = useState({
    phoneNumber: "",
    email: "",
    password: "",
  });

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (!formData.phoneNumber || !formData.email || !formData.password) {
      // console.log("Please fill in all fields");
      alert("Please fill in all fields");
      return;
    }
    // console.log("fetch register");

    if (
      userType !== "tenant" &&
      userType !== "landlord" &&
      userType !== "admin"
    ) {
      alert("role error incorrect");
      return;
    }

    const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/auth/register`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        firstNameInput: e.target[0].value,
        lastNameInput: e.target[1].value,
        firstChineseInput: e.target[2].value,
        lastChineseInput: e.target[3].value,
        phoneInput: e.target[4].value,
        emailInput: e.target[5].value,
        passwordInput: e.target[6].value,
        userType,
      }),
    });

    const response = await res.json();

    if (res.ok) {
    } else {
      alert("Register failed");
    }
  };

  function areFieldsFilled() {
    return formData.phoneNumber && formData.email && formData.password;
  }

  return (
    <div>
      <div className="flex justify-center items-center h-screen">
        <div>
          <label
            htmlFor="tenant-modal"
            onClick={() => setUserType("tenant")}
            className="btn m-2"
          >
            Tenant
          </label>
          <label
            htmlFor="landlord-modal"
            onClick={() => setUserType("landlord")}
            className="btn m-2"
          >
            Landlord
          </label>
        </div>
      </div>

      <div className="content-center bg-cyan-50">
        <input type="checkbox" id="tenant-modal" className="modal-toggle" />
        <div className="modal" role="dialog">
          <div className="modal-box">
            <div className="relative flex flex-col justify-center h-screen overflow-hidden">
              <div
                className="w-full p-8 m-auto bg-brown-100 rounded-md shadow-md ring-3
         ring-gray-800/50 lg:max-w-l"
              >
                <h1 className="text-3xl font-semibold text-center text-gray-700">
                  Sign Up
                </h1>

                <form className="space-y-4" onSubmit={handleSubmit}>
                  <label>First name</label>
                  <br />
                  <input
                    type="text"
                    name="first name"
                    className="w-full input input-bordered"
                    placeholder="Enter your first name"
                  ></input>

                  <br />
                  <label>Last name</label>
                  <br />
                  <input
                    type="text"
                    name="last name"
                    className="w-full input input-bordered"
                    placeholder="Enter your last name"
                  ></input>

                  <br />
                  <label>First name in Chinese</label>
                  <br />
                  <input
                    type="text"
                    name="first name in Chinese"
                    className="w-full input input-bordered"
                    placeholder="Enter your first name in Chinese"
                  ></input>

                  <br />
                  <label>Last name in Chinese</label>
                  <br />
                  <input
                    type="text"
                    name="last name in Chinese"
                    className="w-full input input-bordered"
                    placeholder="Enter your last name in Chinese"
                  ></input>

                  <br />
                  <label>Contact Number</label>
                  <br />
                  <input
                    type="text"
                    name="phoneNumber"
                    className="w-full input input-bordered"
                    placeholder="Enter your Contact Number"
                    required
                    value={formData.phoneNumber}
                    onChange={handleChange}
                  ></input>

                  <br />
                  <label>Email</label>
                  <br />
                  <input
                    type="text"
                    name="email"
                    className="w-full input input-bordered"
                    placeholder="Enter your email"
                    required
                    value={formData.email}
                    onChange={handleChange}
                  ></input>

                  <br />
                  <label>Password</label>
                  <br />
                  <input
                    type="text"
                    name="password"
                    className="w-full input input-bordered"
                    placeholder="Enter your password"
                    required
                    value={formData.password}
                    onChange={handleChange}
                  ></input>
                  <div>
                    {/* Open the modal using document.getElementById('ID').showModal() method */}
                    <button
                      className="btn"
                      onClick={() => {
                        if (areFieldsFilled()) {
                          (
                            document.getElementById(
                              "my_modal_5"
                            ) as HTMLDialogElement
                          )?.showModal();
                        }
                      }}
                    >
                      Sign Up
                    </button>
                    <dialog
                      id="my_modal_5"
                      className="modal modal-bottom sm:modal-middle"
                    >
                      <div className="modal-box">
                        <h3 className="font-bold text-lg">
                          Registration successful!
                        </h3>
                        <p className="py-4">Click the button below to close</p>
                        <div className="modal-action">
                          <form method="dialog">
                            {/* if there is a button in form, it will close the modal */}
                            <button onClick={closePopup} className="btn">
                              Close
                            </button>
                          </form>
                        </div>
                      </div>
                    </dialog>
                  </div>
                  <span>
                    Already have an account ?
                    <a
                      href="#"
                      className="text-blue-600 hover:text-blue-800 hover:underline"
                    >
                      Login
                    </a>
                  </span>
                </form>
              </div>
            </div>
            <div className="modal-action">
              <label htmlFor="tenant-modal" className="btn">
                Close!
              </label>
            </div>
          </div>
        </div>

        <input type="checkbox" id="landlord-modal" className="modal-toggle" />
        <div className="modal" role="dialog">
          <div className="modal-box">
            <div className="relative flex flex-col justify-center h-screen overflow-hidden">
              <div
                className="w-full p-8 m-auto bg-brown-100 rounded-md shadow-md ring-3
         ring-gray-800/50 lg:max-w-l"
              >
                <h1 className="text-3xl font-semibold text-center text-gray-700">
                  Sign Up
                </h1>

                <form className="space-y-4" onSubmit={handleSubmit}>
                  <label>First name</label>
                  <br />
                  <input
                    type="text"
                    name="first name"
                    className="w-full input input-bordered"
                    placeholder="Enter your first name"
                  ></input>

                  <br />
                  <label>Last name</label>
                  <br />
                  <input
                    type="text"
                    name="last name"
                    className="w-full input input-bordered"
                    placeholder="Enter your last name"
                  ></input>

                  <br />
                  <label>First name in Chinese</label>
                  <br />
                  <input
                    type="text"
                    name="first name in Chinese"
                    className="w-full input input-bordered"
                    placeholder="Enter your first name in Chinese"
                  ></input>

                  <br />
                  <label>Last name in Chinese</label>
                  <br />
                  <input
                    type="text"
                    name="last name in Chinese"
                    className="w-full input input-bordered"
                    placeholder="Enter your last name in Chinese"
                  ></input>

                  <br />
                  <label>Contact Number</label>
                  <br />
                  <input
                    type="text"
                    name="phoneNumber"
                    className="w-full input input-bordered"
                    placeholder="Enter your Contact Number"
                    required
                    value={formData.phoneNumber}
                    onChange={handleChange}
                  ></input>

                  <br />
                  <label>Email</label>
                  <br />
                  <input
                    type="text"
                    name="email"
                    className="w-full input input-bordered"
                    placeholder="Enter your email"
                    required
                    value={formData.email}
                    onChange={handleChange}
                  ></input>

                  <br />
                  <label>Password</label>
                  <br />
                  <input
                    type="text"
                    name="password"
                    className="w-full input input-bordered"
                    placeholder="Enter your password"
                    required
                    value={formData.password}
                    onChange={handleChange}
                  ></input>

                  <br />

                  <div>
                    {/* Open the modal using document.getElementById('ID').showModal() method */}
                    <button
                      className="btn"
                      onClick={() => {
                        if (areFieldsFilled()) {
                          (
                            document.getElementById(
                              "my_modal_5"
                            ) as HTMLDialogElement
                          )?.showModal();
                        }
                      }}
                    >
                      Sign Up
                    </button>
                    <dialog
                      id="my_modal_5"
                      className="modal modal-bottom sm:modal-middle"
                    >
                      <div className="modal-box">
                        <h3 className="font-bold text-lg">
                          Registration successful!
                        </h3>
                        <p className="py-4">Click the button below to close</p>
                        <div className="modal-action">
                          <form method="dialog">
                            {/* if there is a button in form, it will close the modal */}
                            <button onClick={closePopup} className="btn">
                              Close
                            </button>
                          </form>
                        </div>
                      </div>
                    </dialog>
                  </div>

                  <span>
                    Already have an account ?
                    <a
                      href="#"
                      className="text-blue-600 hover:text-blue-800 hover:underline"
                    >
                      Login
                    </a>
                  </span>
                </form>
              </div>
            </div>
            <div className="modal-action">
              <label htmlFor="landlord-modal" className="btn">
                Close!
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
