import { useQuery } from "@tanstack/react-query";

interface TenantProfile {
  id: number;
  first_name: string;
  last_name: string;
  first_name_in_chinese: string;
  last_name_in_chinese: string;
  phone_number: string;
  email: string;
  gender: string;
  isMarried: boolean;
  number_of_residents: number;
  employment_status: string;
  has_kids: boolean;
  youngest_kid_education_level: string;
}

export function useTenantProfile() {
  const { isLoading, error, data, isFetching } = useQuery<TenantProfile, Error>(
    {
      queryKey: ["tenantProfile"],
      queryFn: async () => {
        const response = await fetch(
          `${process.env.REACT_APP_API_ENDPOINT}/tenant/profile`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("havenToken")}`,
            },
          }
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      },
    }
  );

  if (isLoading || isFetching) {
    return { status: "loading" };
  } else if (error) {
    return { status: "error", message: error.message };
  } else if (!data) {
    return { status: "no data" };
  }

  return { status: "success", data: data };
}

export async function updateTenantMoreInfo(newData: {
  isMarried: boolean;
  number_of_residents: number;
}) {
  const response = await fetch(
    `${process.env.REACT_APP_API_ENDPOINT}/tenant/profile`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("havenToken")}`,
      },
      body: JSON.stringify({ ...newData }),
    }
  );
}
export async function updatePassword(newData: { password: string }) {
  const response = await fetch(
    `${process.env.REACT_APP_API_ENDPOINT}/tenant/profile`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("havenToken")}`,
      },
      body: JSON.stringify({ ...newData }),
    }
  );
}
export async function updatePhoneNumber(newData: { phone_number: string }) {
  const response = await fetch(
    `${process.env.REACT_APP_API_ENDPOINT}/tenant/profile`,
    {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("havenToken")}`,
      },
      body: JSON.stringify({ ...newData }),
    }
  );
}

export async function toggleLikeRoom(roomId: number, isLiked: boolean) {
  let flag = isLiked ? "unlikeRoom" : "likeRoom";

  const res = await fetch(
    `${process.env.REACT_APP_API_ENDPOINT}/tenant/${flag}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("havenToken")}`,
      },
      body: JSON.stringify({ roomId: roomId }),
    }
  );

  const response = await res.json();

  return response;
}

export async function toggleApplyRoom(roomId: number, isApplied: boolean) {
  try {

  const res = await fetch(
    `${process.env.REACT_APP_API_ENDPOINT}/tenant/applyRoom`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${localStorage.getItem("havenToken")}`,
      },
      body: JSON.stringify({ roomId: roomId}),
    }
    
  );

  if(!res.ok){


// Handle non-2xx responses
const errorText = await res.text();
console.error("Error applying for room:", errorText);
return { error: true, message: errorText };
}


  const response = await res.json();
 

  return response;
}
catch (error) {


}
}