import {useQuery} from "@tanstack/react-query";




interface LandlordProfile {
  id: number;
  first_name: string;
  last_name: string;
  first_name_in_chinese: string;
  last_name_in_chinese: string;
  phone_number: string;
  email: string;

}

export function useLandlordProfile() {
  const { isLoading, error, data, isFetching } = useQuery<LandlordProfile, Error>(
    {
      queryKey: ["landlordProfile"],
      queryFn: async () => {
        const response = await fetch(
          `${process.env.REACT_APP_API_ENDPOINT}/landlord/profile`,
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("havenToken")}`,
            },
          }
        );
        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        return response.json();
      },
    }
  );

  if (isLoading || isFetching) {
    return { status: "loading" };
  } else if (error) {
    return { status: "error", message: error.message };
  } else if (!data) {
    return { status: "no data" };
  }

  return { status: "success", data: data };
}
