import { IconArrowBackUp } from "@tabler/icons-react";
import { Key, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../Hooks/useAuth";


export default function TenantRoomDetailPage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { userRole } = useAuth();
  const [rooms, setRooms] = useState<{ id: number }[]>([]);
  const [waitingList, setWaitingList] = useState<any[]>([]);



 
   

  useEffect(() => {
    const fetchWaitingList = async () => {
      try {
        const res = await fetch(
          `${process.env.REACT_APP_API_ENDPOINT}/admin/getRoomAppliedDetails/${id}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${localStorage.getItem("havenToken")}`,
            },
          }
        );
        const response = await res.json();
        setWaitingList(response.data);
      } catch (error) {
        console.log(error);
      }
    };

    const fetchRoomDetails = async () => {
      try {
        const res = await fetch(
          `${process.env.REACT_APP_API_ENDPOINT}/tenant/getRoomDetails/${id}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("havenToken")}`,
            },
          }
        );
        const response = await res.json();
        setRooms(response.data);
      } catch (error) {
        console.log(error);
      }
    };







    if (id) {
      fetchRoomDetails();

      fetchWaitingList();
    }
  }, []);

  return (
    <>
      <button
        type="button"
        className="btn btn-sm sm:btn-md md:btn-md lg:btn-lg"
        onClick={() => navigate(-1)}
      >
        <IconArrowBackUp stroke={2} />{" "}
      </button>

      <div className="overflow-x-hidden ">
        <div className="requestContainer max-w-full mx-auto ">
          <div className="indicator flex flex-col gap-3 justify-center items-center w-full ">
            {rooms.length > 0 ? (
              rooms.map((room: any, idx) => (
                <div>
                  <div
                    key={idx}
                    className="card bg-base-100 w-70 shadow-2xl my-3"
                  >
                    <img
                      src={`${process.env.REACT_APP_API_ENDPOINT}/${room.image_file_name}`}
                      alt="room_image"
                      className="w-80 h-80 pt-2"
                    />

                    <div className="card-body">
                      <div className="flex justify-between items-center gap-4">
                        <h2 className="card-title text-sm">
                          {room.description}
                        </h2>

                        <p className="badge badge-secondary text-sm px-2 py-0.5">
                          {room.status}
                        </p>
                      </div>
                      <p>rent: $ {room.price}</p>
                      <p>District: {room.district}</p>
                      <p>Space Area: {room.space_area}</p>
                      <p>Number of Bedrooms: {room.bedroom}</p>
                      <p>Number of Bathrooms: {room.bathroom}</p>
                      <p>Number of Kitchens: {room.kitchen}</p>
                      <p>Number of Living Rooms: {room.living_room}</p>
                    </div>
                  </div>
                  {userRole === "admin" ? (
                    <div>
                      <div className="overflow-x-hidden">
                        <div className="requestContainer max-w-full mx-auto pb-20">
                          <div className="indicator flex flex-col gap-3 justify-center items-center w-full">
                            <div className="card bg-base-100 w-70 shadow-2xl my-3">
                              {waitingList.length > 0 ?  (
                                <div>
                                  <div className="card-body bg-base-100 w-70 shadow-2xl ">
                                    <div className="flex justify-between items-center px-4">
                                      <h3
                                        className="card-title text-lg
                                    "
                                      >
                                        {" "}
                                        {/* Move the Applicant title here */}
                                        Applicant
                                      </h3>
                                      <h3 className="card-title text-lg">
                                        Approval
                                      </h3>
                                    </div>
                                    <div className="flex flex-col gap-3">
                                      {waitingList.map((tenant: any) => (
                                        <div
                                          key={id}
                                         
                                          className="flex justify-between items-center gap-8 bg-base-200 p-3 rounded-box"
                                        >
                                          <p  
                                          
                                          
                                          onClick = {() => 
                                          {console.log("Tenant data: ", id);
                                          navigate(`/tenantProfileInfo/${tenant.tenant_id}`)}}>
                                            {tenant.first_name}{" "}
                                            {tenant.last_name}
                                          </p>

                                          <div>
                                            <button className="btn btn-secondary text-xs px-1 py-0.5 mx-1">
                                              Approve
                                            </button>
                                            <button className="btn btn-primary text-xs px-3 py-0.8 mx-1">
                                              Reject
                                            </button>
                                          </div>
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                  
                                </div>
                              ) : (
                                <div>No applicants found</div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <> </>
                  )}
                </div>
              ))
            ) : (
              <div>No rooms found</div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
