import { useState } from "react";
import TenantSearchResults from "./TenantSearchResults";

export default function TenantFilterSearch() {
  const [value, setValue] = useState(0);
  const initialRegion = "";
  const [sliderPos, setSliderPos] = useState(0);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [toggle, setToggle] = useState(false);

  const [selectedRegion, setSelectedRegion] = useState(initialRegion);
  const handleDistrictChange = (e: any) => {
    setSelectedRegion(e.target.value);
  };

  const resetForm = () => {
    setValue(0);
    setSelectedRegion(initialRegion);
    setIsSubmitted(false);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    console.log(value);
    console.log(selectedRegion);
    setIsSubmitted(true);
    setToggle(!toggle);
  };

  function onValueChange(event: React.ChangeEvent<HTMLInputElement>) {
    const newValue = Number(event.target.value);
    setValue(newValue);

    const min = Number(event.target.min);
    const max = Number(event.target.max);

    const percentage = (newValue - min) / (max - min);
    const thumbOffset = percentage * (event.target.clientWidth - 16);
    setSliderPos(thumbOffset);
    // console.log(newValue);
  }

  return (
    <div>
      <form encType="multipart/form-data" onSubmit={handleSubmit}>
        <label className="form-control w-full max-w-ws ">
          <div className="label">
            <span className="label-text-start">$0HKD</span>
            <span className="label-text-end">$20000HKD</span>
          </div>
          <div>{value}</div>
          <input
            type="range"
            min={0}
            max={20000}
            step={100}
            value={value}
            className="range"
            onChange={onValueChange}
          />
          <div className="absolute -top-8" style={{ left: `${sliderPos}px` }}>
            {value}
          </div>
        </label>
        <br></br>

        <span className="Region">Region</span>
        <select
          className="select select-bordered w-full max-w-xl my-4"
          name="regionInput"
          value={selectedRegion}
          onChange={handleDistrictChange}
        >
          <option disabled value="">
            Choose Region Area
          </option>
          <option value="All Regions">All Regions</option>
          <option value="New Territories">New Territories</option>
          <option value="Kowloon">Kowloon</option>
          <option value="Hong Kong Island">Hong Kong Island</option>
        </select>

        <div className="submit-button justify-center space-x-4 mt-4">
          <button type="submit" className="btn">
            Submit
          </button>
          <button type="button" onClick={resetForm} className="btn">
            Reset
          </button>
        </div>
      </form>
      {isSubmitted && (
        <TenantSearchResults
          maxPriceInput={value}
          regionInput={selectedRegion}
          toggle={toggle}
        />
      )}
    </div>
  );
}
