import "../Css/HomePage.css";
import { useAuth } from "../Hooks/useAuth";

export default function HomePage() {

  
  return (
    <div data-theme="retro" className="HomePage">
  <div className="container">

   <div className="row">
      <div className="col-12">

        <div className="mainContainer">
        <div className= "topContainer">
        <div className="carousel rounded-box">
  <div className="carousel-item" id="Banner1">
  <a href="https://www.hk01.com/%E7%A4%BE%E6%9C%83%E6%96%B0%E8%81%9E/997483/%E7%A4%BE%E5%8D%80%E5%AE%A2%E5%BB%B3%E6%96%B0%E5%9D%80%E8%A8%AD%E5%9C%9F%E7%93%9C%E7%81%A3%E7%B4%85%E7%A3%A1%E6%B7%B1%E6%B0%B4%E5%9F%97-%E8%B6%85%E7%B4%9A%E5%A4%A7%E5%BB%B3%E8%B7%9D%E5%AE%8B%E7%9A%87%E8%87%BA%E7%AB%99%E7%B4%8410%E5%88%86%E8%B7%AF%E7%A8%8B">
    <img
      src={process.env.REACT_APP_API_ENDPOINT + "/Banner1.jpeg"}
      alt="news1" />
  </a>
  </div>
  <div className="carousel-item" id="Banner2">
  <a href="https://hk.on.cc/hk/bkn/cnt/news/20240705/bkn-20240705220010271-0705_00822_001.html">
    <img
      src={process.env.REACT_APP_API_ENDPOINT + "/Banner2.jpeg"}
      alt="news2" />
    </a>
  </div>
  <div className="carousel-item">
    <a href="https://www.stheadline.com/realtime-property/3252613/%E9%AB%98%E6%89%8D%E9%80%9A%E5%85%A7%E5%9C%B0%E5%B0%88%E6%89%8D%E6%B9%A7%E6%B8%AF%E5%B8%B6%E6%97%BA%E7%A7%9F%E7%9B%A4-%E9%80%99%E5%8D%8A%E6%96%B0%E7%9B%A4%E4%B8%80%E6%88%BF%E9%9B%A3%E6%B1%82-16%E8%90%AC%E7%A7%9F%E4%B8%80%E6%94%BE%E5%8D%B3%E6%90%B6">
    <img
      src={process.env.REACT_APP_API_ENDPOINT + "/Banner3.jpeg"}
      alt="News3" />
      </a>
  </div>
 
</div>
        </div>
        <div className= "middleContainer">
        <img src={process.env.REACT_APP_API_ENDPOINT + "/testing.jpg"} alt="flats" />

        </div> 
        <div className="bottomContainer">

        <div className="cardContainer">
        <div className="cardTitle ">
        </div>
          <div className="card1 max-w-full font-roboto">
            <h1 className ="font-Quicksand">Renovated Flat</h1>
            <img src={process.env.REACT_APP_API_ENDPOINT + "/demo1.jpeg"} alt="card1Image" className="h-80 w-80"/>
            <p className="font-Quicksand">1 Bedroom <br></br> 1 Bathroom </p>
            <div className="cardButton">
              <button>More Details</button>
            </div>
            </div>
            <div className="card2 font-roboto" id="card2">
            <h1 className="font-Quicksand">Studio Kowloon</h1>
            <img src={process.env.REACT_APP_API_ENDPOINT + "/demo2.jpeg"} alt="card2Image" className="h-80 w80"/>
            <p className="font-Quicksand">Shared Bathroom</p>
            <div className="cardButton">
              <button>More Details</button>
            </div>
            </div>
            
        </div>
    
        </div>
        </div>
        </div>
      </div> 
</div>

    </div>
  
  );  
}