import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

export const useAuth = () => {
  const [authToken, setAuthToken] = useState(
    localStorage.getItem("havenToken") || null
  );

  const decoder = (token: string | null) => {
    if (!token) return false;

    const decodedToken: { userId: number; username: string; userRole: string } =
      jwtDecode(token);
    return decodedToken.userRole;
  };

  const [userRole, setUserRole] = useState(
    decoder(localStorage.getItem("havenToken")) || ""
  );

  const navigate = useNavigate();

  const login = async (token: string) => {
    console.log("check token", token);
    localStorage.setItem("havenToken", token);

    const decodedToken: { userId: number; username: string; userRole: string } =
      await jwtDecode(token!);
    console.log(decodedToken);
    console.log(decodedToken.userRole);
    setAuthToken(token);
    setUserRole(decodedToken.userRole);
    console.log("userRole", decodedToken.userRole);
    if (decodedToken.userRole === "admin") navigate("/admin");
    else if (decodedToken.userRole === "landlord") navigate("/landlord");
    else if (decodedToken.userRole === "tenant") navigate("/tenant");
    else navigate("/login");
  };

  const logout = () => {
    localStorage.removeItem("havenToken");
    setAuthToken(null);
    navigate("/");
  };

  useEffect(() => {
    console.log("useAuth working");
    setAuthToken(localStorage.getItem("havenToken") || null);
    setUserRole(decoder(localStorage.getItem("havenToken")) || "");
  });
  return { authToken, userRole, login, logout };
};
