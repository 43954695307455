import React, { useEffect } from "react";
import "./Css/App.css";
import HomePage from "./Pages/HomePage";
import Navbar from "./Component/Navbar";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import AdminPage from "./Pages/Admin/AdminPage";
import LandlordPage from "./Pages/Landlord/LandlordPage";
import TenantPage from "./Pages/Tenant/TenantPage";
import RegisterPage from "./Pages/Login/RegisterPage";
import LoginPage from "./Pages/Login/LoginPage";
import LoginAdmin from "./Pages/Login/LoginAdmin";
import { useAuth } from "./Hooks/useAuth";
import { AuthGuard } from "./Component/AuthGuard";
import InfoListPage from "./Pages/Tenant/InfoListPage";
import MyProfilePage from "./Pages/MyProfilePage";
import LandlordRoomDetailPage from "./Component/LandlordRoomDetailPage";
import AdminRoomDetailPage from "./Component/AdminRoomDetailPage";
import RoomLikedPage from "./Pages/Tenant/RoomLikedPage";
// Make sure the file path is correct and the file exists in the specified location
import RoomAppliedPage from "./Pages/Tenant/RoomAppliedPage";

import TenantRoomDetailPage from "./Component/TenantRoomDetailPage";
import AdminTenantAppliedDetail from "./Component/AdminTenantAppliedDetail";
import AdminTenantDetailPage from "./Component/AdminTenantDetailPage";
function App() {
  const { authToken, userRole } = useAuth();
  const navigate = useNavigate();

  return (
    <div className="App">
      <Navbar />
      <Routes>
        <Route path="/login" element={<LoginPage />} />
        <Route path="/loginAdmin" element={<LoginAdmin />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route element={<AuthGuard authToken={authToken} />}>
          <Route path="/" element={<Navigate to="/home" />} />
          <Route path="/home" element={<HomePage />} />
          <Route path="/profile" element={<MyProfilePage />} />
        </Route>
        <Route path="/admin" element={<AdminPage />} />
        <Route path="/landlord" element={<LandlordPage />} />
        <Route path="/RoomDetail/:id" element={<LandlordRoomDetailPage />} />
        <Route path="/tenantProfileInfo/:tenant_id" element={<AdminTenantDetailPage />} />


        <Route
          path="/PendingRoomDetail/:id"
          element={<AdminRoomDetailPage />}
        />
        <Route
          path="/getRoomAppliedDetail/:id"
          element={<AdminTenantAppliedDetail />}
        />
        <Route path="/getRoomDetail/:id" element={<TenantRoomDetailPage />} />

        <Route path="/info-list" element={<InfoListPage />} />
        <Route path="/room-liked" element={<RoomLikedPage />} />
        <Route path="/room-applied" element={<RoomAppliedPage />} />
        <Route path="/tenant" element={<TenantPage />} />
      </Routes>
    </div>
  );
}

export default App;
