import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
export default function LandlordPendingApprove() {
  const [rooms, setRooms] = useState([]);
  const [selectedRoomId, setSelectedRoomId] = useState<number | null>(null);
  const navigate = useNavigate();
  const { id } = useParams();

  useEffect(() => {
    const fetchRoom = async () => {
      try {
        const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/landlord/pendingRooms`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("havenToken")}`,
          },
        });
        const response = await res.json();
        setRooms(response.data);
        console.log(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchRoom();
  }, [id]);

  return (
    <>
      <div className="requestContainer">
        <div className="adminRequestBox">
          <div className="indicator flex flex-col gap-4 justify-center items-center w-full">
            <div className="card bg-base-100 w-60 shadow-xl">
              {rooms.length > 0 ? (
                rooms.map((room: any) => (
                  <div
                    className="card bg-base-100 w-60 shadow-2xl my-2"
                    onClick={() => navigate(`/RoomDetail/${room.id}`)}
                  >
                    <figure className="relative">
                      <img
                        src={`${process.env.REACT_APP_API_ENDPOINT}/${room.image_file_name}`}
                        alt="room_image"
                        className="w-40 h-40 pt-3"
                      />
                      {room.status === "pending" && (
                        <div className="absolute inset-0 bg-black bg-opacity-30 backdrop-blur-md flex justify-center items-center">
                          <span className="text-stone-100 text-lg">
                            Pending Approval
                          </span>
                        </div>
                      )}
                    </figure>

                    <div className="card-body">
                      <p className="card-title text-base font-semibold">
                        {room.description}!
                        <div className="flex flex-col gap-y-3">
                          <div className="badge badge-secondary">NEW</div>
                          <div className="badge badge-secondary">
                            {room.status}
                          </div>
                        </div>
                      </p>
                      <p className="font-roboto">Rent: $ {room.price}</p>
                    </div>
                  </div>
                ))
              ) : (
                <div>No rooms found</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
