import React, { useState } from "react";

type ChangePhoneNumberModalProps = {
  onClose: () => void;
  onSubmit: (newPhoneNumber: string) => void;
};
function ChangePhoneNumberModalProps({ onClose }: ChangePhoneNumberModalProps) {
  const [newPhoneNumber, setNewPhoneNumber] = useState("");

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    console.log("handling submit", e.target);
    e.preventDefault();
  
    let res = await fetch(
      `${process.env.REACT_APP_API_ENDPOINT}/tenant/profilePhoneNumber`,
      {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${localStorage.getItem("havenToken")}`,
        },
        body: JSON.stringify({
          phone_number: newPhoneNumber
        }),
      }
    );  
    if (res.ok) {
      alert("Phone Number Changed Successfully");
      onClose();
    } else {
      alert("Phone Number Change Failed");
    }
  };
  
  const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNewPhoneNumber(e.target.value);
  };

  return (
    <div className="modal-backdrop">
      <div className="phoneNumberModal">
        <form onSubmit={handleSubmit}>
          <div className="modal-content">
            <label htmlFor="newPhoneNumber">New Phone Number</label>
            <input
              type="tel"
              id="newPhoneNumber"
              value={newPhoneNumber}
              onChange={handlePhoneNumberChange}
              required
            />
          </div>
          <div className="modal-actions">
            <button type="button" className="close" onClick={onClose}>
              Cancel
            </button>
            <button type="submit" className="submit">
              Submit
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ChangePhoneNumberModalProps;
