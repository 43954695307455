
import React, { useState } from "react";
import { useTenantProfile } from "../Api/tenantProfileAPI";
import {
  IconMail,
  IconLock,
  IconPhone,
  IconLogout,
  IconChevronRight,
  IconInfoSquareRounded,
  IconUserCircle,
} from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../Hooks/useAuth";
import ChangePasswordModal from "../Component/ChangePasswordModal";
import ChangePhoneNumberModalProps from "../Component/ChangePhoneNumberModal";

export default function TenantProfileComponent() {
  const navigate = useNavigate();

  const { logout } = useAuth();
  const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);
  const [isPhoneNumberModalOpen, setIsPhoneNumberModalOpen] = useState(false);

  const openPasswordModal = () => {
    console.log("open modal");
    setIsPasswordModalOpen(true);
  };
  const closePasswordModal = () => setIsPasswordModalOpen(false);

  const openPhoneNumberModal = () => {
    console.log("open modal");
    setIsPhoneNumberModalOpen(true);
  };
  const closePhoneNumberModal = () => setIsPhoneNumberModalOpen(false);

  const tenantProfile = useTenantProfile();

  const handleLogout = async () => {
    await logout();
    navigate("/login");
  };

  const handleClick = () => {
    navigate("/info-list");
  };

  return (
    <div className="container">
      <div className="board">
        <div className="fullName">
          {/* {" "} */}
          <div id="iconUser">
            <IconUserCircle stroke={1.5} />{" "}
          </div>{" "}
          <p>
            {tenantProfile.status === "success" ? (
              tenantProfile.data?.first_name +
              " " +
              tenantProfile.data?.last_name +
              " | " +
              tenantProfile.data?.last_name_in_chinese +
              tenantProfile.data?.first_name_in_chinese
            ) : (
              <></>
            )}
          </p>
        </div>
        <hr className="divider1" />

        <div onClick={handleClick} className="info">
          <div className="info2">
            <div id="iconInfo">
              <IconInfoSquareRounded stroke={1.5} />{" "}
            </div>
            <p>More information for application</p>
          </div>
          <div id="iconChevronRight">
            <IconChevronRight stroke={1.5} />{" "}
          </div>
        </div>
        <hr className="divider1" />
        <div className="email">
          <div id="iconEmail">
            <IconMail stroke={1.5} />{" "}
          </div>
          <p>
            {tenantProfile.status === "success" ? (
              tenantProfile.data?.email
            ) : (
              <></>
            )}
          </p>
        </div>
        <hr className="divider1" />
        <div className="passWord" onClick={openPasswordModal}>
          <div className="passWord2">
            <div id="iconLock">
              <IconLock stroke={1.5} />{" "}
            </div>
            <p>Change your password</p>
          </div>
          <div id="iconChevronRight">
            <IconChevronRight stroke={1.5} />{" "}
          </div>
        </div>
        <hr className="divider1" />
        <div className="phone" onClick={openPhoneNumberModal}>
          <div className="phone2">
            <div id="iconPhone">
              <IconPhone stroke={1.5} />{" "}
            </div>
            <p>
              {tenantProfile.status === "success" ? (
                tenantProfile.data?.phone_number
              ) : (
                <></>
              )}
            </p>
          </div>
          <div id="iconChevronRight">
            <IconChevronRight stroke={1.5} />{" "}
          </div>
        </div>
        <hr className="divider1" />
        <div className="logout">
          <div id="iconLogout">
            <IconLogout stroke={1.5} />{" "}
          </div>
          <p onClick={handleLogout}>Logout</p>
        </div>
      </div>
      {isPasswordModalOpen ? (
        <ChangePasswordModal onClose={closePasswordModal} />
      ) : (
        <> </>
      )}
      {isPhoneNumberModalOpen ? (
        <ChangePhoneNumberModalProps
          onClose={closePhoneNumberModal}
          onSubmit={function (newPhoneNumber: string): void {
            throw new Error("Function not implemented.");
          }}
        />
      ) : (
        <> </>
      )}
    </div>
  );
}
