import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { IconArrowBackUp } from "@tabler/icons-react";
export default function LandlordRoomDetailPage() {
  const [rooms, setRooms] = useState<{ id: number }[]>([]);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const fetchRoomImage = async () => {
      try {
        const res = await fetch(
          `${process.env.REACT_APP_API_ENDPOINT}/landlord/roomDetails/${id}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${localStorage.getItem("havenToken")}`,
            },
          }
        );
        const response = await res.json();
        setRooms(response.data);
        console.log(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    if (id) fetchRoomImage();
  }, [id]);

  return (
    <div>
      <button
        type="button"
        className="btn btn-sm sm:btn-md md:btn-md lg:btn-lg"
        onClick={() => navigate(-1)}
        title="Go Back"
      >
        <IconArrowBackUp stroke={2} />{" "}
      </button>

      <div className="overflow-x-hidden ">
        <div className="requestContainer max-w-full mx-auto ">
          <div className="indicator flex flex-col gap-4 justify-center items-center w-full ">
            {rooms.length > 0 ? (
              rooms.map((room: any, idx) => (
                <div
                  key={idx}
                  className="card bg-base-100 w-60 shadow-2xl my-2"
                >
                  <img
                    src={`${process.env.REACT_APP_API_ENDPOINT}/${room.image_file_name}`}
                    alt="room_image"
                    className="w-90 h-90 pt-2"
                  />

                  <div className="card-body">
                    <h2 className="card-title text-sm">{room.description}</h2>
                    <p>Rent: $ {room.price}</p>
                    <p>District: {room.district}</p>
                    <p>Space Area: {room.space_area}</p>
                    <p>Number of Bedrooms: {room.bedroom}</p>
                    <p>Number of Bathrooms: {room.bathroom}</p>
                    <p>Number of Kitchens: {room.kitchen}</p>
                    <p>Number of Living Rooms: {room.living_room}</p>
                  </div>
                </div>
              ))
            ) : (
              <div>No rooms found</div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
