import "../Css/MyProfilePage.css";
import LandlordProfileComponent from "../Component/LandlordProfileComponent";

import TenantProfileComponent from "../Component/TenantProfileComponent";
import { useAuth } from "../Hooks/useAuth";
import AdminProfileComponent from "../Component/AdminProfileComponent";

export default function MyProfilePage() {
  const { userRole } = useAuth();

  return (
    <>
      <div>
        <div className="profile">
          <div className="background-image">
            <img
              src={process.env.REACT_APP_API_ENDPOINT + "/testing.jpg"}
              alt="Profile"
            />
          </div>
        </div>
      </div>
      {userRole === "admin" && <AdminProfileComponent />}
      {userRole === "tenant" && <TenantProfileComponent />}
      {userRole === "landlord" && <LandlordProfileComponent />}
    </>
  );
}

