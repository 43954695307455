import TenantFilterSearch from "../../Component/TenantFilterSearch";
import RoomAppliedPage from "./RoomAppliedPage";

export default function TenantPage() {
  return (
    <>
      <div>
        <div role="tablist" className="tabs tabs-lifted pt-5 px-3">
          <input
            type="radio"
            name="my_tabs_2"
            role="tab"
            className="tab"
            aria-label="Pending"
          />
          <div
            role="tabpanel"
            className="tab-content bg-base-100 border-base-300 rounded-box p-6 "
          >
            <RoomAppliedPage />
          </div>

          <input
            type="radio"
            name="my_tabs_2"
            role="tab"
            className="tab"
            aria-label="Searching"
            defaultChecked
          />
          <div
            role="tabpanel"
            className="tab-content bg-base-100 border-base-300 rounded-box p-6"
          >
            <TenantFilterSearch/>
          </div>
        </div>
      </div>
    </>
  );
}
