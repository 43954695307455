import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export default function LandlordProperties() {
  const [rooms, setRooms] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchRoomImage = async () => {
      try {
        const res = await fetch(`${process.env.REACT_APP_API_ENDPOINT}/landlord/rooms`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${localStorage.getItem("havenToken")}`,
          },
        });
        const response = await res.json();
        setRooms(response.data);
        console.log(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchRoomImage();
  }, []);

  return (
    <>
      <div>
      <div className="overflow-x-hidden ">
        <div className="requestContainer max-w-full mx-auto ">
          <div className="adminRequestBox">
            <div className="indicator flex flex-col gap-4 justify-center items-center w-full">
              {rooms.length > 0 ? (
                rooms.map((room: any,idx) => (
                  <div key={idx} className="card bg-base-100 w-60 shadow-2xl my-2" onClick={() => navigate(`/RoomDetail/${room.id}`)}>
                    <figure>
                      <img
                        src={`${process.env.REACT_APP_API_ENDPOINT}/${room.image_file_name}`}
                        alt="room_image"
                        className="w-40 h-40 pt-3"
                      />

                    
                    </figure>
                    <div className="card-body">
                      <h2 className="card-title text-sm">
                        {room.description}
                        <div className="badge badge-secondary">NEW</div>
                      </h2>
                      <p>rent: $ {room.price}</p>
                     
                    </div>
                  </div>
                ))
              ) 
              : (
                <div>No rooms found</div>
              )}
            </div>
          </div>
        </div>
      </div>
      </div>
    </>
  );
}

// <div>
// <div className="requestContainer">
//   <div className="adminRequestBox">
//     <div className="indicator">
//       <div className="card bg-base-100 w-60 shadow-xl">
//         <figure>
//           <img
//             src="https://img.daisyui.com/images/stock/photo-1606107557195-0e29a4b5b4aa.jpg"
//             alt="Shoes"
//           />

//           <span className="indicator-item badge badge-secondary">
//             5+
//           </span>
//         </figure>
//         <div className="card-body">
//           <h2 className="card-title">
//             Shoes!
//             <div className="badge badge-secondary">NEW</div>
//           </h2>
//           <p>If a dog chews shoes whose shoes does he choose?</p>
//           <div className="card-actions justify-end">
//             <div className="badge badge-outline">Fashion</div>
//             <div className="badge badge-outline">Products</div>
//           </div>
//         </div>
//       </div>
//     </div>
//   </div>
// </div>
// </div>
