import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

export default function AdminTenantDetailPage() {
  const { tenant_id } = useParams();
  const [tenantDetails, setTenantDetails] = useState<any>({});

  console.log("asdasdasdasd", tenant_id);
  useEffect(() => {
    const fetchTenantDetails = async () => {
      try {
        const res = await fetch(
          `${process.env.REACT_APP_API_ENDPOINT}/admin/tenantProfileInfo/${tenant_id}`,
          {
            method: "GET",
            headers: {
              Authorization: `Bearer ${localStorage.getItem("havenToken")}`,
            },
          }
        );
        const response = await res.json();
        console.log("cat", response);
        setTenantDetails(response);
      } catch (error) {
        console.log(error);
      }
    };
    console.log("banana", tenant_id);
    if (tenant_id) fetchTenantDetails();
  }, [tenant_id]);

  return (
    <>
      <div className="overflow-x-hidden ">
        <div className="requestContainer max-w-full mx-auto ">
          <div className="indicator flex flex-col gap-4 justify-center items-center w-full ">
            <div className="card bg-base-100 w-80 shadow-2xl my-2">
              <div className="card-body flex flex-col items-center">
                <h2 className="card-title text-sm">
                  Tenant Profile Information
                </h2>
                <div className="avatar placeholder">
                  <div className="flex bg-neutral text-neutral-content w-24 h-24 rounded-full justify-center items-center">
                    <span className="text-3xl">D</span>
                  </div>
                </div>
                <p className="text-center">
                  Tenant Name: {tenantDetails.first_name}{" "}
                  {tenantDetails.last_name}
                </p>
                <p className="text-center">Email: {tenantDetails.email}</p>
                <p className="text-center">
                  Phone: {tenantDetails.phone_number}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <div className="overflow-x-hidden ">
        <div className="requestContainer max-w-full mx-auto ">
          <div className="indicator flex flex-col gap-4 justify-center items-center w-full ">
            {tenantDetails.length > 0 ? (
              tenantDetails.map((tenant: any, idx: number) => (
                <div
                  key={idx}
                  className="card bg-base-100 w-60 shadow-2xl my-2"
                >
                  <div className="card-body">
                    <h2 className="card-title text-sm">
                      Tenant Profile Information
                    </h2>
                    <p>
                      Tenant Name: {tenant.first_name} {tenant.last_name}
                    </p>
                  </div>
                </div>
              ))
            ) : (
              <div>No tenant found</div>
            )}
          </div>
        </div>
      </div> */}
    </>
  );
}
