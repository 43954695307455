import React, { useEffect, useState } from "react";
import "../Css/Navbar.css";
import { IconHome, IconUser, IconNews, IconHeart} from "@tabler/icons-react";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../Hooks/useAuth";

export default function Navbar() {
  const { userRole } = useAuth();

  const navigate = useNavigate();

  return (
    <div>
      <div className="navbar">
        <button className="btn" onClick={() => navigate("/home")}>
          <IconHome stroke={2} />
        </button>
        <button className="btn" onClick={() => navigate("/room-liked")}>
        <IconHeart stroke={2} />
          
        </button>

        {userRole === "admin" && (
          <button className="btn" onClick={() => navigate("/admin")}>
            <IconNews stroke={2} />
          </button>
        )}
        {userRole === "tenant" && (
          <button className="btn" onClick={() => navigate("/tenant")}>
            <IconNews stroke={2} />
          </button>
        )}
        {userRole === "landlord" && (
          <button className="btn" onClick={() => navigate("/landlord")}>
            <IconNews stroke={2} />
          </button>
        )}

        <button className="btn" onClick={() => navigate("/profile")}>
          <IconUser stroke={2} />
        </button>
      </div>
    </div>
  );
}
